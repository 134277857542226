import styled from 'styled-components'

import Link from '../../../global/Link'

export {
  ProductList,
  SectionContainer,
  SectionHeader,
  SectionSubTitle,
  SectionTitle,
  ShowMoreButton,
} from '../styled'

export const LinkProductButtonWrapper = styled.div`
  height: 70px;
  padding: 16px 8px 16px 8px;
  border: 1px solid ${({ theme }) => theme.colors.coolGray.cool150};
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.colors.coolGray.cool150};
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 480px) {
    width: calc(50% - 9px);
  }

  @media screen and (min-width: 1024px) {
    width: calc(33.97% - 17px);
`

export const LinkProductButton = styled(Link)`
  padding: 0 16px 0 16px;
  font-family: ${({ theme }) => theme.fonts.global.headers};
  color: ${({ theme }) => theme.colors.chathamsBlue.shade600};
  font-weight: 600;
  text-decoration: none;
`
