import React, { FC } from 'react'
import styled from 'styled-components'

import Link, { LinkProps } from '../global/Link'

import IconOpenSvg from '../../img/drug-nutrient/icon-open.inline.svg'

export type DrugNutrientExternalLinkProps = LinkProps & {
  className?: string
}

const LinkButton = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.component.buttonLink};
  color: ${({ theme }) => theme.colors.royalBlue.shade500};
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
`

const Icon = styled(IconOpenSvg)`
  vertical-align: text-top;
  margin-right: 8px;
  stroke: currentColor;
  path {
    stroke: currentColor;
  }
`

export const DrugNutrientExternalLink: FC<DrugNutrientExternalLinkProps> = ({
  children,
  className,
  ...linkProps
}) => {
  return (
    <LinkButton {...linkProps} className={className}>
      <Icon />
      {children}
    </LinkButton>
  )
}
