import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from '@material-ui/core'
import styled, { css } from 'styled-components'

export const SpecLabel = styled.label`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.08em;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const SpecText = styled.div`
  width: 100%;
  padding-bottom: 10px;
  padding-top: 8px;
  font-size: 16px;
`

export const SnpTitle = styled(SpecLabel)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const SnpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const SpecContainer = styled.div<{ $collapsible: boolean }>`
  ${({ $collapsible }) =>
    $collapsible
      ? css`
          border-bottom: 1px solid #d0d3d8;
          &:last-child {
            border-bottom: none;
          }
        `
      : css`
          margin-top: 32px;
        `}
`

const MuiStyledAccordion = withStyles((theme) => ({
  expanded: {
    margin: '0 !important',
  },
  disabled: {
    backgroundColor: 'inherit !important',
  },
}))(MuiAccordion)
export const Spec = styled(MuiStyledAccordion)`
  box-shadow: none;
`

const MuiStyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: '0 !important',
    // `display` and `padding` are needed, in order to prevent a side effect of toasts
    // affecting styles precedence in MUI buttons
    display: 'flex !important',
    padding: '0 4px !important',
  },
  disabled: {
    opacity: '1 !important',
    pointerEvents: 'auto !important' as 'auto',
    cursor: 'auto !important',
  },
  content: {
    margin: '0 !important',
    height: '50px !important',
  },
}))(MuiAccordionSummary)
export const SpecSummary = styled(MuiStyledAccordionSummary)``

export const SpecSummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const SpecSummaryHeader = styled(SpecSummaryContent)`
  width: fit-content;
`

export const SpecIcon = styled.div`
  width: 16px;
  margin-right: 10px;
`

export const SpecTitle = styled.h4`
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.blackPearlBlue.shade700};
`

export const SpotlightLink = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 5px;
`
