import { bind } from '@react-rxjs/core'
import { createKeyedSignal } from '@react-rxjs/utils'
import { ExecutionResult } from 'graphql'
import { catchError, map, of, switchMap } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'

import { graphqlErrorsMessage, parseQueryResult } from '../../utils/graphqlTools'
import { left, right } from '../../utils/result'
import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { mapRight } from '../../utils/rx/operators'
import { latestAuthStateWithAccessToken$ } from '../auth/state'
import { fetchResponseToJsonResult, headers } from '../fetch'
import { DrugDetails } from './types'

const [drugDetailsSlug$, setDrugDetailsSlug] = createKeyedSignal<string>()
export { setDrugDetailsSlug }

type SanityAllDrugResponse = ExecutionResult<{
  allDrug?: DrugDetails[]
}>

const createSanityDrugQuery = (slug: string) => {
  return {
    query: `
      query drugDetails {
        allDrug(where: { slug: { current: { eq: "${slug}" }}}) {
          _id
          slug {
            current
          }
          name
          brandNamesRaw
          classRaw
          commonUsesRaw
          depletionsRaw
          supportiveInteractionsRaw
          negativeInteractionsRaw
          sideEffectsRaw
          metabolizedByGenesRaw
          inhibitsGenesRaw
          upregulatesGenesRaw
          snps {
            gene
            snp
            notation
            effect
            effectAlleles
            isGenomicSpotlight
            genomicReferences
          }
          cautionRaw
          recommendedProducts {
            sku
          }
          contraindicatedProducts {
            sku
          }
          referencesRaw
        }
      }
    `,
  }
}

export const sanityDrugBySlug$ = (drugSlug: string) =>
  latestAuthStateWithAccessToken$.pipe(
    switchMap((authState) =>
      authState.isAuthenticated
        ? fromFetch('/api/drug-nutrient', {
            body: JSON.stringify(createSanityDrugQuery(drugSlug)),
            method: 'POST',
            headers: {
              ...headers.contentTypeJson,
              ...headers.authorization(authState.accessToken),
            },
          }).pipe(
            switchMap((response) =>
              fetchResponseToJsonResult<SanityAllDrugResponse | undefined>(response),
            ),
            mapRight((result) => parseQueryResult(result.data ?? {})),
            mapRight((result) => ({
              _tag: 'Right' as const,
              data: result.data?.allDrug?.[0] ?? null,
            })),
            catchError(coerceCaughtToLeft),
          )
        : of({
            _tag: 'Left' as const,
            error: new Error('User is not authenticated'),
          }),
    ),
  )

const drugDetails$ = (drugSlug: string) =>
  drugDetailsSlug$(drugSlug).pipe(
    switchMap(() => sanityDrugBySlug$(drugSlug)),
    map((result) =>
      result._tag === 'Right'
        ? result.data
          ? right(result.data)
          : left(new Error(`Drug not found for slug "${drugSlug}"`))
        : left(
            new Error(
              `Error: ${result.error instanceof Error ? result.error.message : graphqlErrorsMessage(result.error)}`,
            ),
          ),
    ),
  )

export const [useDrugDetails, latestDrugDetails$] = bind(drugDetails$)
