import { Card as MuiCard } from '@material-ui/core'
import styled from 'styled-components'

export const Card = styled(MuiCard)`
  margin-top: 32px;
  border-radius: 8px;
  padding: 15px;

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 32px;
    overflow: hidden;
    justify-content: space-between;
  }
`

export const Subtitle = styled.p`
  margin-bottom: 5pt;
`

export const CardSection = styled.div`
  padding: 10px;
`

export const CardSectionSpecs = styled(CardSection)`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 50%;
    max-width: 50%;
  }
`

export const SpecsPageLink = styled.div`
  text-align: right;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 30px;
`
