import BlockContent, {
  ListRendererProps,
  TypeSerializerProps,
} from '@sanity/block-content-to-react'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { DrugNutrientExternalLink } from './DrugNutrientExternalLink'

interface MarkRendererProps<TMark extends Record<string, unknown>> {
  mark: TMark
}

const sharedStyles = css`
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const Paragraph = styled.p`
  ${sharedStyles}
`

export const Heading1 = styled.h1`
  ${sharedStyles}
`

export const Heading2 = styled.h2`
  ${sharedStyles}
`

export const Heading3 = styled.h3`
  ${sharedStyles}
`

export const Heading4 = styled.h4`
  ${sharedStyles}
`

export const Heading5 = styled.h5`
  ${sharedStyles}
`

export const Heading6 = styled.h6`
  ${sharedStyles}
`

export const Blockquote = styled.blockquote`
  ${sharedStyles}
`

export const ListItem = styled.li`
  ${sharedStyles}
`

export const NumberedList = styled.ol`
  ${sharedStyles}
`

export const BulletList = styled.ul`
  ${sharedStyles}
`

export const Anchor = styled(DrugNutrientExternalLink)`
  ${sharedStyles};
  color: ${({ theme }) => theme.colors.brand.blue};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const BlockRenderer: React.FC<TypeSerializerProps> = (props) => {
  const { children, node } = props
  switch (node.style) {
    case 'h1':
      return <Heading1>{children}</Heading1>
    case 'h2':
      return <Heading2>{children}</Heading2>
    case 'h3':
      return <Heading3>{children}</Heading3>
    case 'h4':
      return <Heading4>{children}</Heading4>
    case 'h5':
      return <Heading5>{children}</Heading5>
    case 'h6':
      return <Heading6>{children}</Heading6>
    case 'blockquote':
      return <Blockquote>{children}</Blockquote>
    case 'normal':
      return <Paragraph>{children}</Paragraph>
    default:
      return BlockContent.defaultSerializers.types.block(props)
  }
}

const ListItemRenderer: React.FC = ({ children }) => {
  return <ListItem>{children}</ListItem>
}

const ListRenderer: React.FC<ListRendererProps> = ({ type, children }) => {
  return type === 'number' ? (
    <NumberedList>{children}</NumberedList>
  ) : (
    <BulletList>{children}</BulletList>
  )
}

const LinkMarkRenderer: FC<MarkRendererProps<{ href?: string; anchorHref?: string }>> = ({
  mark,
  children,
}) => {
  const { anchorHref, href } = mark
  return href ? (
    <Anchor rel="noopener noreferrer" target="_blank" to={href}>
      {children}
    </Anchor>
  ) : (
    <Anchor to={anchorHref}>{children}</Anchor>
  )
}

export const serializers = {
  types: {
    block: BlockRenderer,
  },
  marks: {
    link: LinkMarkRenderer,
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
}
