import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Router } from '@gatsbyjs/reach-router'
import React, { FC } from 'react'

import Head from '../../layouts/Head'
import { getRouterBasePath, useDispensaryContext } from '../../lib/dispensaries'
import { drugNutrientPaths } from '../../lib/drug-nutrient/config'
import Layout from '../global/Layout'
import { DrugNutrientDrugDetailsPage } from './DrugNutrientDrugDetailsPage'
import { DrugNutrientDrugSpecsPage } from './DrugNutrientDrugSpecsPage'
import { DrugNutrientHomePage } from './DrugNutrientHomePage'
import { DrugNutrientNotFoundPage } from './DrugNutrientNotFoundPage'
import { DrugNutrientReferencesPage } from './DrugNutrientReferencesPage'
import { DrugNutrientRoute } from './DrugNutrientRoute'

export const DrugNutrient: FC = () => {
  return (
    <>
      <Head title="Drug Nutrient Depletion and Interaction Checker" />
      <GlobalStyle includeTypography />
      <Layout fullWidth>
        <Router basepath={getRouterBasePath(drugNutrientPaths.base, !!useDispensaryContext())}>
          <DrugNutrientRoute
            path={drugNutrientPaths.homePage.relative()}
            component={DrugNutrientHomePage}
          />
          <DrugNutrientRoute
            path={drugNutrientPaths.drugDetailsPage.relative(':drugSlug')}
            component={DrugNutrientDrugDetailsPage}
            paramProps={['drugSlug']}
          />
          <DrugNutrientRoute
            path={drugNutrientPaths.drugSpecsPage.relative(':drugSlug')}
            component={DrugNutrientDrugSpecsPage}
            paramProps={['drugSlug']}
          />
          <DrugNutrientRoute
            path={drugNutrientPaths.drugReferencesPage.relative(':drugSlug')}
            component={DrugNutrientReferencesPage}
            paramProps={['drugSlug']}
          />
          <DrugNutrientRoute default component={DrugNutrientNotFoundPage} />
        </Router>
      </Layout>
    </>
  )
}
