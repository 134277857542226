import React, { FC } from 'react'

import { DrugNutrientBreadcrumbPart } from '../../../lib/drug-nutrient/config'
import * as Styled from './styled'

export type DrugNutrientBreadcrumbProps = {
  className?: string
  breadcrumb: DrugNutrientBreadcrumbPart[]
}

export const DrugNutrientBreadcrumb: FC<DrugNutrientBreadcrumbProps> = ({
  className,
  breadcrumb,
}) => {
  return (
    <Styled.Breadcrumb className={className}>
      {breadcrumb.map(({ path, label }, index) =>
        index === 0 ? (
          <Styled.Root key={`breadcrumb-${index}`} to={path}>
            {label}
          </Styled.Root>
        ) : index === breadcrumb.length - 1 ? (
          <Styled.Current key={`breadcrumb-${index}`}>
            <Styled.Chevron> &gt; </Styled.Chevron>
            {label}
          </Styled.Current>
        ) : (
          <React.Fragment key={`breadcrumb-${index}`}>
            <Styled.Chevron> &gt; </Styled.Chevron>
            <Styled.Part to={path}>{label}</Styled.Part>
          </React.Fragment>
        ),
      )}
    </Styled.Breadcrumb>
  )
}
