import React, { FC } from 'react'

import {
  productsPageSize,
  useDrugNutrientProductsPagination,
} from '../../../../lib/drug-nutrient/hooks/useDrugNutrientProductsPagination'
import { Product } from '../../../../lib/products'
import Spinner from '../../../Spinner'
import * as Styled from '../ContraindicatedProducts/styled'

interface ContraindicatedProductsProps {
  contraindicatedProducts: Product[]
  drugName?: string | null
  loading?: boolean
}

export const DrugNutrientDrugDetailsPageContraindicatedProducts: FC<
  ContraindicatedProductsProps
> = ({ contraindicatedProducts, drugName, loading }) => {
  const { visibleProducts, isShowMore, showMore, showLess } = useDrugNutrientProductsPagination({
    products: contraindicatedProducts,
  })

  if (!loading && contraindicatedProducts.length === 0) {
    return null
  }
  return (
    <>
      <Styled.SectionContainer>
        <Styled.SectionHeader>
          <Styled.SectionTitle>Contraindicated Products</Styled.SectionTitle>
          <Styled.SectionSubTitle>
            when consuming {drugName ? <strong>{drugName}</strong> : 'this drug'}
          </Styled.SectionSubTitle>
        </Styled.SectionHeader>
      </Styled.SectionContainer>
      {loading ? (
        <Spinner loading />
      ) : (
        <>
          <Styled.ProductList>
            {visibleProducts.map((product, index) => (
              <Styled.LinkProductButtonWrapper key={index}>
                <Styled.LinkProductButton to={`/products/${product.urlKey}`}>
                  {product.name}
                </Styled.LinkProductButton>
              </Styled.LinkProductButtonWrapper>
            ))}
          </Styled.ProductList>
          {contraindicatedProducts.length > productsPageSize && (
            <Styled.ShowMoreButton onClick={isShowMore ? showMore : showLess}>
              {isShowMore ? 'Show More' : 'Show Less'}
            </Styled.ShowMoreButton>
          )}
        </>
      )}
    </>
  )
}
