import { useQuery } from '@apollo/client'
import { useContext, useMemo } from 'react'

import { SearchExactParentOrChildSkuDocument } from '../../../graphql/search'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { useRefetchOnChange } from '../../../hooks/useRefetchOnChange'
import { TokenContext } from '../../../layouts/context'
import { isNotNull } from '../../../utils/collectionTools'
import { DrugDetails } from '../types'

export const useDrugNutrientDrugProducts = (drug: DrugDetails | null) => {
  const token = useContext(TokenContext)

  const { recommendedSkus, contraindicatedSkus, allSkus } = useMemo(() => {
    const recommendedProductsSkus =
      drug?.recommendedProducts?.map((product) => product?.sku).filter(isNotNull) || []
    const contraindicatedProductsSkus =
      drug?.contraindicatedProducts?.map((product) => product?.sku).filter(isNotNull) || []
    return {
      recommendedSkus: recommendedProductsSkus,
      contraindicatedSkus: contraindicatedProductsSkus,
      allSkus: [...recommendedProductsSkus, ...contraindicatedProductsSkus],
    }
  }, [drug?.contraindicatedProducts, drug?.recommendedProducts])

  const { data, loading, error, refetch, networkStatus } = useQuery(
    SearchExactParentOrChildSkuDocument,
    {
      context: {
        token,
        uri: process.env.GATSBY_SEARCH_URL,
      },
      variables: { sku: allSkus },
      skip: allSkus.length === 0,
      notifyOnNetworkStatusChange: true,
    },
  )
  useRefetchOnChange({ refetch, networkStatus }, [token])

  useLogAndCaptureError(error)

  const recommendedProducts = useMemo(
    () =>
      recommendedSkus
        .map((sku) => data?.exact.results.find((product) => product.sku === sku))
        .filter(isNotNull),
    [recommendedSkus, data?.exact.results],
  )
  const contraindicatedProducts = useMemo(
    () =>
      contraindicatedSkus
        .map((sku) => data?.exact.results.find((product) => product.sku === sku))
        .filter(isNotNull),
    [contraindicatedSkus, data?.exact.results],
  )

  return {
    recommendedSkus,
    recommendedProducts,
    contraindicatedSkus,
    contraindicatedProducts,
    loading,
  }
}
