import { bind } from '@react-rxjs/core'
import { combineLatest, map } from 'rxjs'

import { latestAuthStateWithAccessToken$ } from '../auth/state'
import { latestUserAccessPolicy$ } from '../auth/user-access-policy'

type DrugNutrientAuthState = {
  state: 'anonymous' | 'unauthorized' | 'authorized'
}

const drugNutrientAuthState$ = combineLatest([
  latestAuthStateWithAccessToken$,
  latestUserAccessPolicy$,
]).pipe(
  map(
    ([{ isAuthenticated }, { canReadDrugNutrient }]): DrugNutrientAuthState => ({
      state: isAuthenticated ? (canReadDrugNutrient ? 'authorized' : 'unauthorized') : 'anonymous',
    }),
  ),
)

export const [useDrugNutrientAuthState, latestDrugNutrientAuthState$] = bind(drugNutrientAuthState$)
