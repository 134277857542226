import styled, { css } from 'styled-components'

import { preservePrintColor } from '../../../utils/styles'

import headingBackground from '../../../img/drug-nutrient/page-heading-background.jpeg'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

export const PrintLogo = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};

  @media print {
    display: block;
  }
  ${preservePrintColor}
`

export const PrintLogoContainer = styled.div`
  padding: 50px 20px 10px;
  margin: 0 auto;
  max-width: 1200px;
`

export const PrintLogoImage = styled.img`
  display: block;
  width: 270px;
`

export const HeadingContainer = styled.div`
  background-image: url('${headingBackground}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${preservePrintColor}
`

export const Heading = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.white};
    text-shadow: 5px 4px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 0;
    text-align: center;
    padding: 0 10px;
    font-size: 24px;
    @media (min-width: 600px) {
      font-size: 36px;
    }
  `}
`

export const HeadingLine = styled.span`
  display: block;
  @media (min-width: 980px) {
    display: inline;
  }
`

export const Content = styled.div`
  width: 100%;
  min-width: 320px;
  max-width: 850px;
  padding: 0 20px;
  margin: 40px auto 50px;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`
