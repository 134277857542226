import React, { FC, useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { filter, firstValueFrom } from 'rxjs'

import useOutsideClick from '../../../hooks/useOutsideClick'
import { drugNutrientPaths } from '../../../lib/drug-nutrient/config'
import { latestDrugDetails$ } from '../../../lib/drug-nutrient/details'
import {
  latestDrugSearchResults$,
  setDrugSearchEnabled,
  setDrugSearchQuery,
} from '../../../lib/drug-nutrient/search'
import { usePageUrl } from '../../../lib/pages'
import { isRight } from '../../../utils/result'
import { DrugNutrientSearchInput, searchInputName } from './Input'
import { DrugNutrientSearchResultList } from './ResultList'
import * as Styled from './styled'

export interface DrugNutrientSearchProps {
  currentDrugSlug?: string
}

interface DrugNutrientSearchFieldValues {
  query: string
}

export const DrugNutrientSearch: FC<DrugNutrientSearchProps> = ({ currentDrugSlug }) => {
  const { navigate } = usePageUrl()
  const methods = useForm<DrugNutrientSearchFieldValues>()
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods
  const searchQuery = watch(searchInputName)

  const { elementRef: outsideClickRef } = useOutsideClick<HTMLDivElement>(() => {
    setDrugSearchEnabled(false)
  })

  const disableOnEscape = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setDrugSearchEnabled(false)
    }
  }, [])

  const enableOnFocus = useCallback(() => {
    setDrugSearchEnabled(true)
  }, [])

  const onSubmit = useCallback(async () => {
    const results = await firstValueFrom(latestDrugSearchResults$)
    if (isRight(results) && results.data && results.data.length > 0) {
      const [{ slug }] = results.data
      if (slug?.current) {
        navigate(drugNutrientPaths.drugDetailsPage.absolute(slug?.current))
      }
    }
  }, [navigate])

  const clearSearch = useCallback(() => {
    setValue(searchInputName, '')
  }, [setValue])

  useEffect(() => {
    setDrugSearchQuery(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    if (dirtyFields.query) {
      setDrugSearchEnabled(true)
    }
  }, [dirtyFields.query])

  useEffect(() => {
    if (!currentDrugSlug) {
      return
    }
    const subscription = latestDrugDetails$(currentDrugSlug)
      .pipe(filter(isRight))
      .subscribe((drug) => {
        setValue(searchInputName, drug.data.name, { shouldDirty: false })
      })
    return () => subscription.unsubscribe()
  }, [currentDrugSlug, setValue])

  return (
    <Styled.Container ref={outsideClickRef} onKeyUp={disableOnEscape} onFocus={enableOnFocus}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Search>
            <DrugNutrientSearchInput clearSearch={clearSearch} />
          </Styled.Search>
        </form>
      </FormProvider>
      <DrugNutrientSearchResultList />
    </Styled.Container>
  )
}
