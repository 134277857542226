import { useCallback, useMemo, useState } from 'react'

import { prescriptionBuilderActions } from '../../prescriptions/builder/actions'
import { PrescriptionProduct } from '../../prescriptions/builder/types'
import { useAddToPrescriptionToast } from '../../prescriptions/hooks/useAddToPrescriptionToast'
import { usePrescriptionBuilder } from '../../prescriptions/hooks/usePrescriptionBuilder'

export type DrugNutrientAddToPrescription = [
  addProductsToPrescription: () => Promise<void>,
  addProductsToPrescriptionState: { loading: boolean },
]

export const useDrugNutrientAddToPrescription = (
  products: { sku: string; urlKey: string }[],
  initialLoading: boolean,
): DrugNutrientAddToPrescription => {
  const [loading, setLoading] = useState(false)
  const { dispatch } = usePrescriptionBuilder()
  const { showSuccessToast } = useAddToPrescriptionToast()
  const prescriptionProducts = useMemo(
    (): PrescriptionProduct[] =>
      products.map((product) => ({ ...product, quantity: 1, autoShip: null })),
    [products],
  )

  const addProductsToPrescription = useCallback(
    async () =>
      new Promise<void>((resolve) => {
        if (initialLoading) {
          return
        }
        setLoading(true)
        setTimeout(
          () => {
            showSuccessToast({ products: prescriptionProducts })
            dispatch(prescriptionBuilderActions.addMany(prescriptionProducts))
            setLoading(false)
            resolve()
          },
          500 + Math.floor(Math.random() * 300),
        )
      }),
    [dispatch, showSuccessToast, prescriptionProducts, initialLoading],
  )

  return [addProductsToPrescription, { loading }]
}
