import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'

import Spinner from '../Spinner'

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

const StyledSpinner = styled(Spinner)`
  // make the loading component be taller, so that the footer is not in the middle of the screen
  height: 60vh;
`

export const DrugNutrientLoadingPage: FC = () => (
  <>
    <Helmet>
      <title>Loading...</title>
    </Helmet>
    <Container>
      <StyledSpinner loading />
    </Container>
  </>
)
