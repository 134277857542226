import BlockContent from '@sanity/block-content-to-react'
import React, { FC } from 'react'

import { DrugDetails } from '../../../lib/drug-nutrient/types'
import { serializers } from '../serializers'
import * as Styled from './styled'

export interface DrugNutrientDrugSpecsOverviewProps {
  drug: DrugDetails
  actions?: React.ReactNode
}

export const DrugNutrientDrugSpecsOverview: FC<DrugNutrientDrugSpecsOverviewProps> = ({
  drug,
  actions,
}) => {
  return (
    <>
      <Styled.Header>
        <Styled.TitleWrapper>
          <Styled.Title>{drug.name}</Styled.Title>
          {drug.brandNamesRaw && (
            <Styled.Subtitle>
              <BlockContent blocks={drug.brandNamesRaw} serializers={serializers} />
            </Styled.Subtitle>
          )}
        </Styled.TitleWrapper>
        {actions && <Styled.Actions>{actions}</Styled.Actions>}
      </Styled.Header>
      {drug.classRaw && (
        <>
          <Styled.Label>Class</Styled.Label>
          <Styled.CardText>
            <BlockContent blocks={drug.classRaw} serializers={serializers} />
          </Styled.CardText>
        </>
      )}
      {drug.commonUsesRaw && (
        <>
          <Styled.Label>Common Uses</Styled.Label>
          <Styled.CardText>
            <BlockContent blocks={drug.commonUsesRaw} serializers={serializers} />
          </Styled.CardText>
        </>
      )}
    </>
  )
}
