import { useMutation } from '@apollo/client'
import { useCallback, useContext, useMemo } from 'react'

import { AddSimpleProductsToCartDocument } from '../../../graphql/magento'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { TokenContext } from '../../../layouts/context'
import { checkQueryResponse, tapSuccessfulQueryResponse } from '../../../utils/graphqlTools'
import { publishAddToCartEventForItems } from '../../analytics/cart'
import { useCartContext } from '../../cart'
import { useAddToCartToast } from '../../cart/hooks/useAddToCartToast'

export type DrugNutrientAddToCart = [
  addProductsToCart: () => Promise<void>,
  addProductsToCartState: { loading: boolean },
]

export const useDrugNutrientAddToCart = (
  products: { sku: string; urlKey: string }[],
  initialLoading: boolean,
): DrugNutrientAddToCart => {
  const token = useContext(TokenContext)
  const { cart } = useCartContext()
  const { showSuccessToast, showFailureToast } = useAddToCartToast()
  const skus = useMemo(() => products.map(({ sku }) => sku), [products])

  const [addSimpleProductsToCart, { loading: addToCartLoading, error: addToCartError }] =
    useMutation(AddSimpleProductsToCartDocument, {
      context: { token },
    })

  useLogAndCaptureError(addToCartError)

  const addProductsToCart = useCallback(async () => {
    if (initialLoading || !cart?.id || skus.length === 0) {
      return
    }
    return addSimpleProductsToCart({
      variables: {
        cartId: cart.id,
        cartItems: skus.map((sku) => ({
          data: { sku, quantity: 1 },
        })),
      },
    })
      .then(
        tapSuccessfulQueryResponse(
          publishAddToCartEventForItems({
            items: skus.map((sku) => ({
              sku,
              quantity: 1,
            })),
          }),
        ),
      )
      .then(checkQueryResponse)
      .then((data) => {
        if (data?.addSimpleProductsToCart) {
          showSuccessToast({ skus })
        }
      })
      .catch(() => {
        showFailureToast({ skus })
      })
  }, [addSimpleProductsToCart, cart?.id, showSuccessToast, showFailureToast, skus, initialLoading])

  return [addProductsToCart, { loading: addToCartLoading }]
}
