import { FormHelperText, InputAdornment } from '@material-ui/core'
import React, { FC } from 'react'
import { FieldError, useController, useFormContext } from 'react-hook-form'

import { DrugNutrientSearchInputEndAdornment } from './InputEndAdornment'
import * as Styled from './styled'

export interface DrugNutrientSearchInputProps {
  clearSearch: () => void
}

export const searchInputName = 'query'

export const DrugNutrientSearchInput: FC<DrugNutrientSearchInputProps> = ({ clearSearch }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { field } = useController({
    control,
    name: searchInputName,
    rules: { required: true },
    defaultValue: '',
  })
  const error = (errors[searchInputName] as FieldError | undefined)?.message || undefined

  return (
    <Styled.InputWrapper variant="outlined">
      <Styled.Input
        placeholder="Find a brand or drug name"
        name={searchInputName}
        inputComponent={Styled.InputElem}
        inputRef={field.ref}
        onChange={field.onChange}
        value={field.value}
        error={!!error}
        notched={false}
        required
        startAdornment={
          <InputAdornment position="start">
            <Styled.SearchIconButton tabIndex="-1">
              <Styled.SearchIcon />
            </Styled.SearchIconButton>
          </InputAdornment>
        }
        endAdornment={
          <DrugNutrientSearchInputEndAdornment value={field.value} clearSearch={clearSearch} />
        }
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Styled.InputWrapper>
  )
}
