import React, { FC, useCallback } from 'react'

import { drugNutrientPaths } from '../../../lib/drug-nutrient/config'
import { setDrugSearchEnabled } from '../../../lib/drug-nutrient/search'
import { DrugSearchResult } from '../../../lib/drug-nutrient/types'
import * as Styled from './styled'

export const DrugNutrientSearchResult: FC<{ result: DrugSearchResult }> = ({ result }) => {
  const disableOnClick = useCallback(() => {
    setDrugSearchEnabled(false)
  }, [])
  return (
    <Styled.ResultLine
      to={
        result.slug?.current
          ? drugNutrientPaths.drugDetailsPage.absolute(result.slug.current)
          : undefined
      }
      onClick={disableOnClick}
    >
      <Styled.ResultDrugName>{result.name}</Styled.ResultDrugName>
      {result.brandNamesText && (
        <Styled.ResultDrugBrandNames>{result.brandNamesText}</Styled.ResultDrugBrandNames>
      )}
    </Styled.ResultLine>
  )
}
