// when updating, also update the path in `vercel.config.ts` rewrites
import { normalizePath } from '../pages'

export interface DrugNutrientBreadcrumbPart {
  label: string
  path?: string
}

export interface DrugNutrientBreadcrumbDrug {
  name?: string | null
  slug?: string | null
}

const basePath = normalizePath('/drug-nutrient-interaction')

const absolute = (path: string) => `${basePath}${normalizePath(path)}`
const relativeAndAbsolute = <Params extends string[]>(fn: (...params: Params) => string) => ({
  relative: (...params: Params) => normalizePath(fn(...params)),
  absolute: (...params: Params) => absolute(fn(...params)),
})

export const drugNutrientPaths = {
  base: basePath,
  homePage: relativeAndAbsolute(() => `/`),
  drugDetailsPage: relativeAndAbsolute((drugSlug: string) => `/drug${normalizePath(drugSlug)}`),
  drugSpecsPage: relativeAndAbsolute(
    (drugSlug: string) => `/drug${normalizePath(drugSlug)}/details`,
  ),
  drugReferencesPage: relativeAndAbsolute(
    (drugSlug: string) => `/drug${normalizePath(drugSlug)}/references`,
  ),
}

export const drugNutrientBreadcrumb = {
  homePage: (): DrugNutrientBreadcrumbPart[] => [
    { label: 'Drug Nutrient Depletion Checker', path: drugNutrientPaths.homePage.absolute() },
  ],
  drugDetailsPage: ({ name, slug }: DrugNutrientBreadcrumbDrug): DrugNutrientBreadcrumbPart[] => [
    ...drugNutrientBreadcrumb.homePage(),
    {
      label: name ?? 'Drug',
      path: slug ? drugNutrientPaths.drugDetailsPage.absolute(slug) : undefined,
    },
  ],
  drugSpecsPage: ({ name, slug }: DrugNutrientBreadcrumbDrug): DrugNutrientBreadcrumbPart[] => [
    ...drugNutrientBreadcrumb.drugDetailsPage({ name, slug }),
    { label: 'Details', path: slug ? drugNutrientPaths.drugSpecsPage.absolute(slug) : undefined },
  ],
  drugReferencesPage: ({
    name,
    slug,
  }: DrugNutrientBreadcrumbDrug): DrugNutrientBreadcrumbPart[] => [
    ...drugNutrientBreadcrumb.drugDetailsPage({ name, slug }),
    {
      label: 'References',
      path: slug ? drugNutrientPaths.drugReferencesPage.absolute(slug) : undefined,
    },
  ],
}
