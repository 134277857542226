import { useAuth0 } from '@auth0/auth0-react'
import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router'
import { Subscribe } from '@react-rxjs/core'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import {
  latestDrugNutrientAuthState$,
  useDrugNutrientAuthState,
} from '../../lib/drug-nutrient/auth'
import { drugNutrientPaths } from '../../lib/drug-nutrient/config'
import { usePageUrl } from '../../lib/pages'
import { EmptyObject } from '../../utils/types'
import Spinner from '../Spinner'
import { DrugNutrientLoadingPage } from './DrugNutrientLoadingPage'
import { DrugNutrientNotFoundPage } from './DrugNutrientNotFoundPage'
import { DrugNutrientPrintContextProvider } from './DrugNutrientPrintContextProvider'

export type DrugNutrientRouteProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TComponent extends React.ComponentType<any>,
  TParamProps extends Exclude<keyof React.ComponentProps<TComponent>, 'children'> = never,
> = RouteComponentProps<EmptyObject> &
  Omit<React.ComponentProps<TComponent>, TParamProps> & {
    component: TComponent
    // props supplied by reach-router dynamic segments
    paramProps?: ReadonlyArray<TParamProps>
  }

const StyledSpinner = styled(Spinner)`
  // make the loading component be taller, so that the footer is not in the middle of the screen
  height: 60vh;
`

const DrugNutrientRouteContent = <
  // Note: `any` seems to be required for inference - may be related to bivariance
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TComponent extends React.ComponentType<any>,
  TParamProps extends Exclude<keyof React.ComponentProps<TComponent>, 'children'> = never,
>({
  component,
  componentProps,
}: {
  component: TComponent
  componentProps: Omit<
    DrugNutrientRouteProps<TComponent, TParamProps>,
    'component' | 'path' | 'paramProps'
  >
}) => {
  const { getPagePath, navigate } = usePageUrl()
  const { loginWithRedirect } = useAuth0()
  const location = useLocation()
  const { state } = useDrugNutrientAuthState()

  useEffect(() => {
    if (state !== 'anonymous') {
      return
    }
    loginWithRedirect({
      appState: {
        returnTo: location
          ? `${location.pathname}${location.search}`
          : getPagePath(drugNutrientPaths.homePage.absolute()),
      },
    }).catch(() => navigate('/login'))
  }, [state, loginWithRedirect, location, getPagePath, navigate])

  if (state === 'anonymous') {
    return <DrugNutrientLoadingPage />
  }
  if (state === 'unauthorized') {
    return <DrugNutrientNotFoundPage />
  }
  return (
    <DrugNutrientPrintContextProvider>
      {React.createElement(component, componentProps)}
    </DrugNutrientPrintContextProvider>
  )
}

export const DrugNutrientRoute = <
  // Note: `any` seems to be required for inference - may be related to bivariance
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TComponent extends React.ComponentType<any>,
  TParamProps extends Exclude<keyof React.ComponentProps<TComponent>, 'children'> = never,
>({
  component,
  path,
  paramProps: _paramProps,
  ...componentProps
}: DrugNutrientRouteProps<TComponent, TParamProps>): React.ReactElement | null => {
  // avoid SSR errors since Suspense not supported in this ReactDOMServer version
  // https://react.dev/errors/294?invariant=294
  if (typeof window === 'undefined') {
    return null
  }
  return (
    <Subscribe source$={latestDrugNutrientAuthState$} fallback={<StyledSpinner loading />}>
      <DrugNutrientRouteContent component={component} componentProps={componentProps} />
    </Subscribe>
  )
}
