interface PortableTextChild {
  _type: string
  text?: string
}

interface PortableTextBlock {
  _type: 'block'
  children: PortableTextChild[]
}

function isPortableTextBlock(value: unknown): value is PortableTextBlock {
  return (
    typeof value === 'object' &&
    value != null &&
    (value as PortableTextBlock)._type === 'block' &&
    Array.isArray((value as PortableTextBlock).children)
  )
}

export function toPlainText(blocks: unknown): string {
  if (!blocks || !Array.isArray(blocks)) {
    return ''
  }
  const reduceChild = (acc: string, child: PortableTextChild): string => {
    const { text } = child
    return typeof text === 'string' ? `${acc}${text}` : acc
  }
  return blocks
    .map((block) => {
      if (!isPortableTextBlock(block)) {
        return ''
      }
      return block.children.reduce(reduceChild, '')
    })
    .join('\n\n')
}
