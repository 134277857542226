import { Alert } from '@designsforhealth/dfh-react-components'
import { Subscribe } from '@react-rxjs/core'
import BlockContent from '@sanity/block-content-to-react'
import React, { FC, useEffect } from 'react'

import { useResultWithLeftEffect } from '../../../hooks/useResultWithLeftEffect'
import { drugNutrientBreadcrumb } from '../../../lib/drug-nutrient/config'
import {
  latestDrugDetails$,
  setDrugDetailsSlug,
  useDrugDetails,
} from '../../../lib/drug-nutrient/details'
import { constNull } from '../../../utils/constant'
import { pluckData } from '../../../utils/result'
import Spinner from '../../Spinner'
import { DrugNutrientBreadcrumb } from '../DrugNutrientBreadcrumb'
import { DrugNutrientLayout } from '../DrugNutrientLayout'
import { DrugNutrientNotFoundPage } from '../DrugNutrientNotFoundPage'
import * as Styled from '../DrugNutrientReferencesPage/styled'
import { DrugNutrientSearch } from '../DrugNutrientSearch'
import { serializers } from '../serializers'

export interface DrugNutrientReferencesPageProps {
  drugSlug: string
}

export const DrugNutrientReferencesPageContent: FC<DrugNutrientReferencesPageProps> = ({
  drugSlug,
}) => {
  const [error, drug] = useResultWithLeftEffect(useDrugDetails(drugSlug), pluckData, constNull)

  if (!drug) {
    return <DrugNutrientNotFoundPage />
  }
  return (
    <>
      {error && <Alert type="error">{error.error.message}</Alert>}
      <DrugNutrientBreadcrumb
        breadcrumb={drugNutrientBreadcrumb.drugReferencesPage({
          name: drug.name,
          slug: drug.slug?.current,
        })}
      />
      <Styled.Container>
        <Styled.Header>
          <Styled.TitleWrapper>
            <Styled.Title>{drug.name} References</Styled.Title>
          </Styled.TitleWrapper>
        </Styled.Header>
        <BlockContent blocks={drug.referencesRaw} serializers={serializers} />
      </Styled.Container>
    </>
  )
}

export const DrugNutrientReferencesPage: FC<DrugNutrientReferencesPageProps> = ({ drugSlug }) => {
  useEffect(() => {
    setDrugDetailsSlug(drugSlug)
  }, [drugSlug])
  return (
    <DrugNutrientLayout>
      <DrugNutrientSearch currentDrugSlug={drugSlug} />
      <Subscribe source$={latestDrugDetails$(drugSlug)} fallback={<Spinner loading />}>
        <DrugNutrientReferencesPageContent drugSlug={drugSlug} />
      </Subscribe>
    </DrugNutrientLayout>
  )
}
