import { AccordionDetails } from '@material-ui/core'
import React, { FC, useState } from 'react'

import * as Styled from './styled'

import IconAdd from '../../../img/drug-nutrient/icon-add.inline.svg'
import IconRemove from '../../../img/drug-nutrient/icon-remove.inline.svg'

export interface DrugAccordionDrugSpecProps {
  title: string
  icon?: React.ReactNode
  collapsible?: boolean
}

export const DrugNutrientDrugSpec: FC<DrugAccordionDrugSpecProps> = ({
  children,
  title,
  icon,
  collapsible = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Styled.SpecContainer $collapsible={collapsible}>
      <Styled.Spec
        key={title}
        expanded={isOpen || !collapsible}
        onChange={toggleAccordion}
        disabled={!collapsible}
      >
        <Styled.SpecSummary>
          <Styled.SpecSummaryContent>
            <Styled.SpecSummaryHeader>
              {icon ? <Styled.SpecIcon>{icon}</Styled.SpecIcon> : null}
              <Styled.SpecTitle>{title}</Styled.SpecTitle>
            </Styled.SpecSummaryHeader>
            {collapsible && (isOpen ? <IconRemove /> : <IconAdd />)}
          </Styled.SpecSummaryContent>
        </Styled.SpecSummary>
        <AccordionDetails>
          <Styled.SpecText>{children}</Styled.SpecText>
        </AccordionDetails>
      </Styled.Spec>
    </Styled.SpecContainer>
  )
}
