import { Alert } from '@designsforhealth/dfh-react-components'
import { Subscribe } from '@react-rxjs/core'
import React, { FC } from 'react'

import { useResultWithLeftEffect } from '../../../hooks/useResultWithLeftEffect'
import { latestDrugSearchResults$, useDrugSearchResults } from '../../../lib/drug-nutrient/search'
import { constNull } from '../../../utils/constant'
import { graphqlErrorsMessage } from '../../../utils/graphqlTools'
import { pluckData } from '../../../utils/result'
import { DrugNutrientSearchResult } from './Result'
import * as Styled from './styled'

const DrugNutrientSearchResultListContent: FC = () => {
  const [error, results] = useResultWithLeftEffect(useDrugSearchResults(), pluckData, constNull)
  if (error) {
    return (
      <Alert type="error" hideAlertIcon>
        {error.error instanceof Error ? error.error.message : graphqlErrorsMessage(error.error)}
      </Alert>
    )
  }
  if (!results) {
    return null
  }
  return (
    <Styled.ResultList>
      {results.length > 0 ? (
        <>
          <Styled.ResultListTitle>Drug Name</Styled.ResultListTitle>
          <Styled.ResultListContent>
            {results.map((result, index) => (
              <DrugNutrientSearchResult
                key={result.slug?.current ?? result.name ?? `result-${index}`}
                result={result}
              />
            ))}
          </Styled.ResultListContent>
        </>
      ) : (
        <Styled.ResultListEmpty>No matches for the provided search terms.</Styled.ResultListEmpty>
      )}
    </Styled.ResultList>
  )
}

export const DrugNutrientSearchResultList: FC = () => {
  return (
    <Subscribe source$={latestDrugSearchResults$}>
      <DrugNutrientSearchResultListContent />
    </Subscribe>
  )
}
