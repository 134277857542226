import styled from 'styled-components'

import Link from '../../../global/Link'
import LabelStatusIconImport from '../../../LabelStatusIcon'

export const AddToCartButton = styled.button`
  text-transform: unset;
  height: 46px;
  font-size: 16px;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 8px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  width: 100%;
  color: ${({ theme }) => theme.colors.grayscale.white};
  &:hover {
    cursor: pointer;
  },

  @media(min-width: 1200px) {
    font-size: 1rem;
  },
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
`

export const LabelStatusIcon = styled(LabelStatusIconImport)`
  position: absolute;
  margin-left: 5px;
  margin-top: 3px;
`

export const ProductListCardContainer = styled.div<{ hideFilters: boolean }>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border: 1px solid ${({ theme }) => theme.colors.coolGray.cool150};
  border-radius: 4px;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-direction: column;
  hyphens: auto;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-wrap: break-word;
  box-sizing: border-box;
  box-shadow: 0 0 2px ${({ theme }) => theme.colors.coolGray.cool250};

  a {
    color: ${({ theme }) => theme.colors.grayscale.black};
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  h4 {
    padding: 15px 15px 8px;
  }

  img {
    height: 225px;
    max-width: 100%;
    object-fit: contain;
  }

  @media (min-width: 375px) {
    width: calc(50% - 0.25rem);
    margin-bottom: unset;
  }
  @media (min-width: 600px) {
    width: calc(100% / 3 - 0.17rem);
  }
  @media (min-width: 768px) {
    width: calc(25% - 0.19rem);
  }
  @media (min-width: 992px) {
    min-width: 200px;
    width: ${({ hideFilters }) =>
      hideFilters ? 'calc(25% - 0.375rem)' : 'calc(100% / 3 - 0.34rem)'};
  }
  @media (min-width: 1200px) {
    width: calc(25% - 0.375rem);
  }
`

export const ProductImage = styled(Link)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.coolGray.cool100};
  flex-direction: column;

  img {
    align-self: center;
  },
`

export const ProductName = styled(Link)`
  font-size: 16px;
  font-family: Gotham, sans-serif;
  padding: 15px 0;
  letter-spacing: 1px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.chathamsBlue.shade600} !important;
`

export const ProductDetailsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 15px 15px;
`

export const ProductAttributes = styled.div`
  display: flex;
  height: 100%;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.darkPurple.shade700};
  justify-content: flex-end;
  flex-direction: column;
`

export const ProductAttribute = styled.p`
  font-size: 10px;
  font-family: Roboto, sans-serif;
  margin: 0 0 4px;
`

export const ProductPrice = styled.p`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 0 8px 0;
  width: 100%;
`

export const SizeTag = styled.div`
  display: flex;
  line-height: normal;
  width: fit-content;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  padding: 4px 8px;
  white-space: nowrap;
  font-size: 8px;
  color: ${({ theme }) => theme.colors.royalBlue.shade800};
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.colors.royalBlue.shade800};
  border-radius: 28px;
  background-color: ${({ theme }) => theme.colors.coolGray.cool150};
`

export const DosageTag = styled.div`
  font-family: Roboto, sans-serif;
  margin: 0 0 5px;
  white-space: nowrap;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 400;
`

export const ProductMinimumPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.blackPearlBlue.shade700};
`

export const ProductMinimumPriceText = styled.span`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 325;
  line-height: 12px;
  margin-bottom: 4px;
`

export const ProductMinimumPriceAmount = styled.span`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 600;
  line-height: 19.2px;
`

export const ProductRetailPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutralGray.neutral500};
`

export const ProductRetailPriceText = styled.span`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 325;
  line-height: 12px;
  margin-bottom: 4px;
`

export const ProductRetailPriceAmount = styled.span`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 600;
  line-height: 19.2px;
`

export const ScrollableContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  gap: 4px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none;
  },
`
