import React, { FC } from 'react'

import { useDrugNutrientAddToCart } from '../../../../lib/drug-nutrient/hooks/useDrugNutrientAddToCart'
import { useDrugNutrientAddToPrescription } from '../../../../lib/drug-nutrient/hooks/useDrugNutrientAddToPrescription'
import { useDrugNutrientDrugProductsVariants } from '../../../../lib/drug-nutrient/hooks/useDrugNutrientDrugProductsVariants'
import type { Product } from '../../../../lib/products'
import CircularProgressVariant from '../../../global/CircularProgressVariant'
import * as Styled from './styled'

import IconCart from '../../../../img/drug-nutrient/icon-cart.inline.svg'
import IconPrescription from '../../../../img/drug-nutrient/icon-escript.inline.svg'

interface RecommendedProductsProps {
  products: Product[]
  loading?: boolean
}

export const DrugNutrientDrugDetailsPageRecommendedProductsActions: FC<
  RecommendedProductsProps
> = ({ products, loading }) => {
  const { defaultVariants, loading: loadingVariants } =
    useDrugNutrientDrugProductsVariants(products)
  const loadingInitial = loading || loadingVariants
  const [addAllToCart, { loading: loadingAddToCart }] = useDrugNutrientAddToCart(
    defaultVariants,
    loadingInitial,
  )
  const [addAllToPrescription, { loading: loadingAddToPrescription }] =
    useDrugNutrientAddToPrescription(defaultVariants, loadingInitial)

  return (
    <Styled.ButtonWrapper>
      <Styled.SecondaryButton
        disabled={loadingInitial || loadingAddToPrescription}
        onClick={addAllToPrescription}
      >
        <Styled.ButtonContent>
          {loadingAddToPrescription ? (
            <CircularProgressVariant />
          ) : (
            <>
              Add All to eScript
              <IconPrescription />
            </>
          )}
        </Styled.ButtonContent>
      </Styled.SecondaryButton>
      <Styled.PrimaryButton disabled={loadingInitial || loadingAddToCart} onClick={addAllToCart}>
        <Styled.ButtonContent>
          {loadingAddToCart ? (
            <CircularProgressVariant />
          ) : (
            <>
              Add All to Cart
              <IconCart />
            </>
          )}
        </Styled.ButtonContent>
      </Styled.PrimaryButton>
    </Styled.ButtonWrapper>
  )
}
