import { useCallback, useMemo, useState } from 'react'

import { Product } from '../../products'

export interface UseDrugNutrientProductsPaginationOptions {
  products: Product[]
}

export const productsPageSize = 6

export const useDrugNutrientProductsPagination = ({
  products,
}: UseDrugNutrientProductsPaginationOptions) => {
  const [visibleProductsCount, setVisibleProductsCount] = useState<number>(productsPageSize)
  const isShowMore = visibleProductsCount < products.length
  const visibleProducts = useMemo(
    () => products.slice(0, visibleProductsCount),
    [products, visibleProductsCount],
  )

  const showMore = useCallback(() => {
    setVisibleProductsCount((count) => count + productsPageSize)
  }, [])

  const showLess = useCallback(() => {
    setVisibleProductsCount(productsPageSize)
  }, [])

  return {
    visibleProducts,
    isShowMore,
    showMore,
    showLess,
  }
}
