import styled from 'styled-components'

import { DrugNutrientExternalLink } from '../DrugNutrientExternalLink'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 16px;
`

export const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0;
`

export const SectionSubTitle = styled.p`
  margin-bottom: 0;
`

export const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 30px;
  margin-bottom: 16px;
`

export const ShowMoreButton = styled.button`
  background: none;
  border: none;
  font-family: ${({ theme }) => theme.fonts.component.button};
  letter-spacing: 0.06em;
  width: 100%;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
`

export const DrugNutrientExternalLinkReference = styled(DrugNutrientExternalLink)`
  color: ${({ theme }) => theme.colors.coolGray.cool900};
`

export const DrugNutrientDrugDetailsPageContent = styled.div`
  margin-bottom: 130px;
`
