import React, { FC } from 'react'
import styled from 'styled-components'

import { drugNutrientPaths } from '../../../lib/drug-nutrient/config'
import * as Styled from './styled'

export interface ReferencesProps {
  drugSlug: string
}

export const LinkWrapper = styled.div`
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 10px;
`

export const DrugNutrientDrugDetailsPageReferences: FC<ReferencesProps> = ({ drugSlug }) => {
  return (
    <>
      <Styled.SectionContainer>
        <Styled.SectionHeader>
          <Styled.SectionTitle>References</Styled.SectionTitle>
        </Styled.SectionHeader>
      </Styled.SectionContainer>
      <LinkWrapper>
        <Styled.DrugNutrientExternalLinkReference
          to={drugNutrientPaths.drugReferencesPage.absolute(drugSlug)}
        >
          View all references
        </Styled.DrugNutrientExternalLinkReference>
      </LinkWrapper>
    </>
  )
}
