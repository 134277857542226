import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 10px;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`

export const TitleWrapper = styled.div`
  flex-grow: 1;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.brand.blue};
`

export const Subtitle = styled.p`
  margin-bottom: 5pt;
`

export const Actions = styled.div``

export const Label = styled.label`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.08em;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const CardText = styled.div`
  margin-bottom: 10px;
  margin-top: 8px;
  font-size: 16px;
`
