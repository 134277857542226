import { Alert } from '@designsforhealth/dfh-react-components'
import { Subscribe } from '@react-rxjs/core'
import React, { FC, useEffect } from 'react'

import { useResultWithLeftEffect } from '../../../hooks/useResultWithLeftEffect'
import { drugNutrientBreadcrumb } from '../../../lib/drug-nutrient/config'
import {
  latestDrugDetails$,
  setDrugDetailsSlug,
  useDrugDetails,
} from '../../../lib/drug-nutrient/details'
import { useDrugNutrientDrugProducts } from '../../../lib/drug-nutrient/hooks/useDrugNutrientDrugProducts'
import { constNull } from '../../../utils/constant'
import { pluckData } from '../../../utils/result'
import Spinner from '../../Spinner'
import { DrugNutrientBreadcrumb } from '../DrugNutrientBreadcrumb'
import { DrugNutrientDrugCard } from '../DrugNutrientDrugCard'
import { DrugNutrientLayout } from '../DrugNutrientLayout'
import { DrugNutrientNotFoundPage } from '../DrugNutrientNotFoundPage'
import { DrugNutrientSearch } from '../DrugNutrientSearch'
import { DrugNutrientDrugDetailsPageContraindicatedProducts } from './ContraindicatedProducts'
import { DrugNutrientDrugDetailsPageRecommendedProducts } from './RecommendedProducts'
import { DrugNutrientDrugDetailsPageReferences } from './References'
import * as Styled from './styled'

export interface DrugNutrientDrugDetailsPageProps {
  drugSlug: string
}

export const DrugNutrientDrugDetailsPageContent: FC<DrugNutrientDrugDetailsPageProps> = ({
  drugSlug,
}) => {
  const [error, drug] = useResultWithLeftEffect(useDrugDetails(drugSlug), pluckData, constNull)
  const {
    recommendedSkus,
    recommendedProducts,
    contraindicatedSkus,
    contraindicatedProducts,
    loading,
  } = useDrugNutrientDrugProducts(drug)

  if (!drug) {
    return <DrugNutrientNotFoundPage />
  }
  return (
    <Styled.DrugNutrientDrugDetailsPageContent>
      {error && <Alert type="error">{error.error.message}</Alert>}
      <DrugNutrientBreadcrumb
        breadcrumb={drugNutrientBreadcrumb.drugDetailsPage({
          name: drug.name,
          slug: drug.slug?.current,
        })}
      />
      <DrugNutrientDrugCard drug={drug} />
      {recommendedSkus.length > 0 && (
        <DrugNutrientDrugDetailsPageRecommendedProducts
          products={recommendedProducts}
          drugName={drug.name}
          loading={loading}
        />
      )}
      {contraindicatedSkus.length > 0 && (
        <DrugNutrientDrugDetailsPageContraindicatedProducts
          contraindicatedProducts={contraindicatedProducts}
          drugName={drug.name}
          loading={loading}
        />
      )}
      {drug?.referencesRaw && drug.slug?.current && (
        <DrugNutrientDrugDetailsPageReferences drugSlug={drug.slug.current} />
      )}
    </Styled.DrugNutrientDrugDetailsPageContent>
  )
}

export const DrugNutrientDrugDetailsPage: FC<DrugNutrientDrugDetailsPageProps> = ({ drugSlug }) => {
  useEffect(() => {
    setDrugDetailsSlug(drugSlug)
  }, [drugSlug])
  return (
    <DrugNutrientLayout>
      <DrugNutrientSearch currentDrugSlug={drugSlug} />
      <Subscribe source$={latestDrugDetails$(drugSlug)} fallback={<Spinner loading />}>
        <DrugNutrientDrugDetailsPageContent drugSlug={drugSlug} />
      </Subscribe>
    </DrugNutrientLayout>
  )
}
