import BlockContent from '@sanity/block-content-to-react'
import React, { FC, useContext } from 'react'

import { DrugNutrientPrintContext } from '../../../lib/drug-nutrient/context'
import { DrugDetails } from '../../../lib/drug-nutrient/types'
import { isNotNull } from '../../../utils/collectionTools'
import { DrugNutrientExternalLink } from '../DrugNutrientExternalLink'
import { serializers } from '../serializers'
import { DrugNutrientDrugSpec } from './Spec'
import * as Styled from './styled'

import IconGenes from '../../../img/drug-nutrient/icon-genes.inline.svg'

export interface DrugNutrientDrugSpecGenesProps {
  drug: DrugDetails
  collapsible?: boolean
}

export const DrugNutrientDrugSpecGenes: FC<DrugNutrientDrugSpecGenesProps> = ({
  drug,
  collapsible = true,
}) => {
  const { isPrinting } = useContext(DrugNutrientPrintContext)
  return (
    <DrugNutrientDrugSpec title="Genes" icon={<IconGenes />} collapsible={collapsible}>
      {drug.metabolizedByGenesRaw && (
        <>
          <Styled.SpecLabel>Metabolized by</Styled.SpecLabel>
          <Styled.SpecText>
            <BlockContent blocks={drug.metabolizedByGenesRaw} serializers={serializers} />
          </Styled.SpecText>
          <Styled.SpotlightLink>
            <DrugNutrientExternalLink to="/products/genomic-spotlight">
              Order a Spotlight test to check this gene’s presence
            </DrugNutrientExternalLink>
          </Styled.SpotlightLink>
        </>
      )}
      {drug.inhibitsGenesRaw && (
        <>
          <Styled.SpecLabel>Inhibits</Styled.SpecLabel>
          <Styled.SpecText>
            <BlockContent blocks={drug.inhibitsGenesRaw} serializers={serializers} />
          </Styled.SpecText>
        </>
      )}
      {drug.upregulatesGenesRaw && (
        <>
          <Styled.SpecLabel>Upregulates</Styled.SpecLabel>
          <Styled.SpecText>
            <BlockContent blocks={drug.upregulatesGenesRaw} serializers={serializers} />
          </Styled.SpecText>
        </>
      )}
      <Styled.SnpTitle>SNPs</Styled.SnpTitle>
      <Styled.SnpContainer>
        {drug.snps?.filter(isNotNull).map((snp, index) => (
          <DrugNutrientDrugSpec
            title={
              snp.gene && snp.snp
                ? snp.gene + ': ' + snp.snp
                : (snp.gene ? snp.gene : snp.snp) || 'Unknown'
            }
            key={index}
            collapsible={!isPrinting}
          >
            {snp.gene && (
              <>
                <Styled.SpecLabel>Gene</Styled.SpecLabel>
                <Styled.SpecText>{snp.gene}</Styled.SpecText>
              </>
            )}
            {snp.snp && (
              <>
                <Styled.SpecLabel>SNP</Styled.SpecLabel>
                <Styled.SpecText>{snp.snp}</Styled.SpecText>
              </>
            )}
            {snp.notation && (
              <>
                <Styled.SpecLabel>Label / Notation</Styled.SpecLabel>
                <Styled.SpecText>{snp.notation}</Styled.SpecText>
              </>
            )}
            {snp.effectAlleles && (
              <>
                <Styled.SpecLabel>
                  Effect Alleles (converted to &quot;+&quot; orientation)
                </Styled.SpecLabel>
                <Styled.SpecText>{snp.effectAlleles}</Styled.SpecText>
              </>
            )}
            {snp.effect && (
              <>
                <Styled.SpecLabel>Reaction or Effect</Styled.SpecLabel>
                <Styled.SpecText>{snp.effect}</Styled.SpecText>
              </>
            )}
            {snp.isGenomicSpotlight != null && (
              <>
                <Styled.SpecLabel>Genomic Spotlight</Styled.SpecLabel>
                <Styled.SpecText>{snp.isGenomicSpotlight ? 'Yes' : 'No'}</Styled.SpecText>
              </>
            )}
            {snp.genomicReferences && (
              <>
                <Styled.SpecLabel>Genomic Reference</Styled.SpecLabel>
                <Styled.SpecText>
                  <DrugNutrientExternalLink
                    to={snp.genomicReferences}
                    target="_blank"
                    title={snp.genomicReferences}
                  >
                    {snp.genomicReferences}
                  </DrugNutrientExternalLink>
                </Styled.SpecText>
              </>
            )}
          </DrugNutrientDrugSpec>
        ))}
      </Styled.SnpContainer>
    </DrugNutrientDrugSpec>
  )
}
