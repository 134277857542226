import { useQuery } from '@apollo/client'
import { useContext, useMemo } from 'react'

import { GetProductsVariantsByParentSkusDocument } from '../../../graphql/magento'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { TokenContext } from '../../../layouts/context'
import { isNotNull } from '../../../utils/collectionTools'
import { formattedVariantData } from '../../../utils/itemTools'
import { Product } from '../../products'

const getFirstVariantSku = (
  usedAttributes: string[],
  attributeProductList: Record<string, Record<string, string[]>>,
): string | null => {
  let currentList = attributeProductList
  for (const attribute of usedAttributes) {
    const firstValue = Object.keys(currentList[attribute] || {})[0]
    if (firstValue) {
      const nextList = currentList[attribute][firstValue]
      if (Array.isArray(nextList)) {
        return nextList[0]
      }
      currentList = nextList
    }
  }
  return null
}

export const useDrugNutrientDrugProductsVariants = (products: Product[] | null) => {
  const token = useContext(TokenContext)

  const parentProductsSkus = useMemo(
    () => products?.map((product) => product.sku) ?? [],
    [products],
  )

  const { data, loading, error } = useQuery(GetProductsVariantsByParentSkusDocument, {
    returnPartialData: true,
    context: { token },
    variables: { skus: parentProductsSkus },
  })

  useLogAndCaptureError(error)

  const variantData = useMemo(
    () =>
      data?.products?.items
        ?.map((item) => {
          if (!item || !item.sku || !item.url_key) {
            return null
          }
          const variantIndex =
            item.__typename === 'ConfigurableProduct' && item.variant_index
              ? formattedVariantData({ variant_index: item.variant_index })
              : null
          const defaultVariantSku = variantIndex
            ? getFirstVariantSku(variantIndex.usedAttributes, variantIndex.attributeProductList)
            : null
          return {
            sku: item.sku,
            urlKey: item.url_key,
            variantIndex,
            defaultVariantSku,
          }
        })
        .filter(isNotNull) ?? [],
    [data],
  )

  const defaultVariants = useMemo(
    () =>
      variantData
        .map(({ sku, urlKey, variantIndex, defaultVariantSku }) => ({
          sku: defaultVariantSku || sku,
          urlKey,
        }))
        .filter(isNotNull) || [],
    [variantData],
  )

  return {
    loading,
    variantData,
    defaultVariants,
  }
}
