import React, { FC } from 'react'

import { DrugNutrientPrintContext } from '../../lib/drug-nutrient/context'
import { useDrugNutrientPrint } from '../../lib/drug-nutrient/hooks/useDrugNutrientPrint'

export const DrugNutrientPrintContextProvider: FC = ({ children }) => {
  const value = useDrugNutrientPrint()
  return (
    <DrugNutrientPrintContext.Provider value={value}>{children}</DrugNutrientPrintContext.Provider>
  )
}
