import { InputAdornment } from '@material-ui/core'
import { Subscribe } from '@react-rxjs/core'
import React, { FC } from 'react'

import {
  latestSanityDrugsSearchQuery$,
  useSanityDrugsSearchQuery,
} from '../../../lib/drug-nutrient/search'
import * as Styled from './styled'

export interface DrugNutrientSearchInputEndAdornmentProps {
  value: string
  clearSearch: () => void
}

const InputEndAdornmentContent: FC<DrugNutrientSearchInputEndAdornmentProps> = ({
  value,
  clearSearch,
}) => {
  useSanityDrugsSearchQuery()
  if (!value) {
    return <></>
  }
  return (
    <InputAdornment position="end">
      <Styled.ClearIconButton onClick={clearSearch} tabIndex="-1">
        <Styled.ClearIcon />
      </Styled.ClearIconButton>
    </InputAdornment>
  )
}

export const DrugNutrientSearchInputEndAdornment: FC<DrugNutrientSearchInputEndAdornmentProps> = ({
  value,
  clearSearch,
}) => (
  <Subscribe
    source$={latestSanityDrugsSearchQuery$}
    fallback={
      <InputAdornment position="end">
        <Styled.LoadingIcon />
      </InputAdornment>
    }
  >
    <InputEndAdornmentContent value={value} clearSearch={clearSearch} />
  </Subscribe>
)
