import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { ExecutionResult, GraphQLError } from 'graphql'
import {
  catchError,
  combineLatest,
  debounceTime,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs'
import { fromFetch } from 'rxjs/fetch'

import { Drug } from '../../graphql/api'
import { parseQueryResult } from '../../utils/graphqlTools'
import { toPlainText } from '../../utils/portableTextTools'
import { Result, right } from '../../utils/result'
import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { mapRight } from '../../utils/rx/operators'
import { latestAuthStateWithAccessToken$ } from '../auth/state'
import { fetchResponseToJsonResult, headers } from '../fetch'
import { DrugSearchResult } from './types'

export type DrugSearchState =
  | { _tag: 'disabled' }
  | { _tag: 'empty' }
  | { _tag: 'query'; query: string }

const [drugSearchEnabled$, setDrugSearchEnabled] = createSignal<boolean>()
const [drugSearchQuery$, setDrugSearchQuery] = createSignal<string | null>()

export type SanityDrugSearchResponse = ExecutionResult<{
  allDrug?: Pick<Drug, '_id' | 'slug' | 'name' | 'brandNamesRaw'>[]
}>

const createSanityDrugSearchQuery = () => {
  return {
    query: `
      query drugsSearch {
        allDrug {
          _id
          slug {
            current
          }
          name
          brandNamesRaw
        }
      }
    `,
  }
}

export const sanityDrugsSearchQuery$ = latestAuthStateWithAccessToken$.pipe(
  switchMap((authState) =>
    authState.isAuthenticated
      ? fromFetch('/api/drug-nutrient', {
          body: JSON.stringify(createSanityDrugSearchQuery()),
          method: 'POST',
          headers: {
            ...headers.contentTypeJson,
            ...headers.authorization(authState.accessToken),
          },
        }).pipe(
          switchMap((response) =>
            fetchResponseToJsonResult<SanityDrugSearchResponse | undefined>(response),
          ),
          mapRight((result) => parseQueryResult(result.data ?? {})),
          mapRight((result) => ({
            _tag: 'Right' as const,
            data: (result.data?.allDrug ?? []).map(
              (drug): DrugSearchResult => ({
                ...drug,
                brandNamesText: drug.brandNamesRaw ? toPlainText(drug.brandNamesRaw) : undefined,
              }),
            ),
          })),
          catchError(coerceCaughtToLeft),
        )
      : of({
          _tag: 'Left' as const,
          error: new Error('User is not authenticated'),
        }),
  ),
  shareReplay(1),
)

export const [useSanityDrugsSearchQuery, latestSanityDrugsSearchQuery$] =
  bind(sanityDrugsSearchQuery$)

const stringMatches =
  (query: string) =>
  (candidate: string): boolean =>
    candidate.toLowerCase().includes(query.toLowerCase())

const searchDrugByQuery =
  (drugs: DrugSearchResult[]) =>
  (query: string): DrugSearchResult[] =>
    drugs.filter(
      (drug) =>
        ((drug.name && stringMatches(query)(drug.name)) ||
          (drug.brandNamesText && stringMatches(query)(drug.brandNamesText))) ??
        false,
    )

const searchDrugByQuery$ =
  (query: string) => (result: Result<DrugSearchResult[], Error | ReadonlyArray<GraphQLError>>) =>
    result._tag === 'Right' ? right(searchDrugByQuery(result.data)(query)) : result

const drugSearchState$ = combineLatest([
  drugSearchEnabled$.pipe(startWith(false)),
  drugSearchQuery$.pipe(
    debounceTime(100),
    map((query) => query?.trim() ?? null),
  ),
]).pipe(
  map(
    ([enabled, query]): DrugSearchState =>
      enabled
        ? query
          ? { _tag: 'query' as const, query }
          : { _tag: 'empty' as const }
        : { _tag: 'disabled' as const },
  ),
)

const drugSearchResults$ = combineLatest([sanityDrugsSearchQuery$, drugSearchState$]).pipe(
  map(([result, state]) =>
    state._tag === 'disabled'
      ? right(null)
      : state._tag === 'query'
        ? searchDrugByQuery$(state.query)(result)
        : right(null),
  ),
)

export const [useDrugSearchResults, latestDrugSearchResults$] = bind(
  drugSearchResults$,
  right(null),
)
export { setDrugSearchEnabled, setDrugSearchQuery }
