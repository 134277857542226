import React, { FC } from 'react'

import {
  productsPageSize,
  useDrugNutrientProductsPagination,
} from '../../../../lib/drug-nutrient/hooks/useDrugNutrientProductsPagination'
import type { Product } from '../../../../lib/products'
import Spinner from '../../../Spinner'
import { DrugNutrientDrugDetailsPageRecommendedProductsActions } from './Actions'
import * as Styled from './styled'

interface RecommendedProductsProps {
  products: Product[]
  drugName?: string | null
  loading?: boolean
}

export const DrugNutrientDrugDetailsPageRecommendedProducts: FC<RecommendedProductsProps> = ({
  products,
  drugName,
  loading,
}) => {
  const { visibleProducts, isShowMore, showMore, showLess } = useDrugNutrientProductsPagination({
    products,
  })

  if (!loading && products.length === 0) {
    return null
  }
  return (
    <>
      <Styled.SectionContainer>
        <Styled.SectionHeader>
          <Styled.SectionTitle>Recommended Products</Styled.SectionTitle>
          <Styled.SectionSubTitle>
            for patients that are taking {drugName ? <strong>{drugName}</strong> : 'this drug'}
          </Styled.SectionSubTitle>
        </Styled.SectionHeader>
        <DrugNutrientDrugDetailsPageRecommendedProductsActions
          products={products}
          loading={loading}
        />
      </Styled.SectionContainer>
      {loading ? (
        <Spinner loading />
      ) : (
        <>
          <Styled.ProductList>
            {visibleProducts.map((product) => (
              <Styled.RecommendedProductListCard key={product.id} product={product} />
            ))}
          </Styled.ProductList>
          {products.length > productsPageSize && (
            <Styled.ShowMoreButton onClick={isShowMore ? showMore : showLess}>
              {isShowMore ? 'Show More' : 'Show Less'}
            </Styled.ShowMoreButton>
          )}
        </>
      )}
    </>
  )
}
