import styled from 'styled-components'

import ProductListCard from '../../../products/lists/ProductListCard'
import { SectionContainer as StyledSectionContainer } from '../styled'

export {
  ProductList,
  SectionHeader,
  SectionSubTitle,
  SectionTitle,
  ShowMoreButton,
} from '../styled'

export const SectionContainer = styled(StyledSectionContainer)`
  align-items: start;
  gap: 25px;
`

export const RecommendedProductListCard = styled(ProductListCard)`
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.colors.coolGray.cool150};
  width: calc(100%);

  @media screen and (min-width: 480px) {
    width: calc(50% - 9px);
  }

  @media screen and (min-width: 1024px) {
    width: calc(33.97% - 17px);
  }
`

export const Button = styled.button`
  text-transform: unset;
  height: 46px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.component.button};
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (min-width: 768px) {
    width: fit-content;
    min-width: 190px;
  }
`

export const PrimaryButton = styled(Button)`
  border: none;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  color: ${({ theme }) => theme.colors.grayscale.white};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.coolGray.cool400};
  }

  @media screen and (min-width: 768px) {
    min-width: 160px;
  }
`

export const SecondaryButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.brand.blue};
  background-color: ${({ theme }) => theme.colors.brand.white};
  color: ${({ theme }) => theme.colors.brand.blue};

  &:disabled {
    border-color: ${({ theme }) => theme.colors.coolGray.cool400};
    background-color: ${({ theme }) => theme.colors.coolGray.cool400};
  }

  @media screen and (min-width: 768px) {
    min-width: 180px;
  }
`

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 5px;
    width: fit-content;
  }
`

export const ButtonWrapper = styled(ButtonContent)`
  margin: 0;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`
