import { Chip as MuiChip } from '@material-ui/core'
import styled from 'styled-components'

export const HomeSection = styled.div`
  margin-top: 80px;
`

export const HomeSectionWithMessage = styled.div`
  margin-top: 40px;
`

export const Chips = styled.div`
  margin-top: 24px;
`

export const Chip = styled(MuiChip)`
  margin: 0px 10px 10px 0px;
  height: 38px;
  border-radius: 19px;
`

export const Disclaimer = styled.p`
  font-style: italic;
  font-family: ${({ theme }) => theme.fonts.global.body} !important;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const DrugAdditionRequest = styled.p`
  font-size: 13px;
  margin-bottom: 0;
`

export const Email = styled.a`
  color: ${({ theme }) => theme.colors.component.link};
`
