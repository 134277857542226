import React, { FC, useContext } from 'react'

import { DrugNutrientPrintContext } from '../../../lib/drug-nutrient/context'
import { FooterPrint } from '../../global/footer'
import * as Styled from './styled'

import printLogoImage from '../../../img/logo-designs-for-health.svg'

export const DrugNutrientLayout: FC = ({ children }) => {
  const { isPrinting, contentElementRef } = useContext(DrugNutrientPrintContext)
  return (
    <Styled.Container ref={contentElementRef} tabIndex={-1}>
      {isPrinting && (
        <Styled.PrintLogo>
          <Styled.PrintLogoContainer>
            <Styled.PrintLogoImage src={printLogoImage} />
          </Styled.PrintLogoContainer>
        </Styled.PrintLogo>
      )}
      <Styled.HeadingContainer>
        <Styled.Heading>
          <Styled.HeadingLine>Drug Nutrient Depletion</Styled.HeadingLine>{' '}
          <Styled.HeadingLine>and Interaction Checker</Styled.HeadingLine>
        </Styled.Heading>
      </Styled.HeadingContainer>
      <Styled.Content>{children}</Styled.Content>
      {isPrinting && <FooterPrint />}
    </Styled.Container>
  )
}
