import { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import { DrugNutrientPrintContextValue } from '../context'

export const useDrugNutrientPrint = (): DrugNutrientPrintContextValue => {
  const contentElementRef = useRef<HTMLDivElement | null>(null)
  const [documentTitle, setDocumentTitle] = useState(
    'Drug Nutrient Depletion and Interaction Checker',
  )
  const [isPrinting, setIsPrinting] = useState(false)
  const promiseResolveRef = useRef<(() => void) | null>(null)
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const handlePrint = useReactToPrint({
    documentTitle: documentTitle,
    content: () => contentElementRef.current,
    // removeAfterPrint: true,
    onBeforeGetContent: async () =>
      new Promise<void>((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      }),
    onAfterPrint: () => {
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
  })

  return { isPrinting, handlePrint, contentElementRef, setDocumentTitle }
}
