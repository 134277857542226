import React, { FC } from 'react'

import { drugNutrientPaths } from '../../../lib/drug-nutrient/config'
import { DrugDetails } from '../../../lib/drug-nutrient/types'
import { DrugNutrientDrugSpecsList } from '../DrugNutrientDrugSpecsList'
import { DrugNutrientDrugSpecsOverview } from '../DrugNutrientDrugSpecsOverview'
import { DrugNutrientExternalLink } from '../DrugNutrientExternalLink'
import * as Styled from './styled'

export interface DrugNutrientDrugCardProps {
  drug: DrugDetails
}

export const DrugNutrientDrugCard: FC<DrugNutrientDrugCardProps> = ({ drug }) => {
  return (
    <Styled.Card variant="outlined">
      <Styled.CardSection>
        <DrugNutrientDrugSpecsOverview drug={drug} />
      </Styled.CardSection>
      <Styled.CardSectionSpecs>
        <div>
          <DrugNutrientDrugSpecsList drug={drug} collapsible />
        </div>
        <Styled.SpecsPageLink>
          {drug.slug?.current && (
            <DrugNutrientExternalLink
              to={drugNutrientPaths.drugSpecsPage.absolute(drug.slug.current)}
            >
              Open full drug details
            </DrugNutrientExternalLink>
          )}
        </Styled.SpecsPageLink>
      </Styled.CardSectionSpecs>
    </Styled.Card>
  )
}
