import BlockContent from '@sanity/block-content-to-react'
import React, { FC } from 'react'

import { DrugDetails } from '../../../lib/drug-nutrient/types'
import { serializers } from '../serializers'
import { DrugNutrientDrugSpec } from './Spec'
import { DrugNutrientDrugSpecGenes } from './SpecGenes'

import IconCaution from '../../../img/drug-nutrient/icon-caution.inline.svg'
import IconDepletions from '../../../img/drug-nutrient/icon-depletions.inline.svg'
import IconNegInteractions from '../../../img/drug-nutrient/icon-neg-interactions.inline.svg'
import IconSideEffects from '../../../img/drug-nutrient/icon-side-effects.inline.svg'
import IconSuppInteractions from '../../../img/drug-nutrient/icon-supp-interactions.inline.svg'

export interface DrugNutrientDrugSpecsListProps {
  drug: DrugDetails
  collapsible?: boolean
}

export const DrugNutrientDrugSpecsList: FC<DrugNutrientDrugSpecsListProps> = ({
  drug,
  collapsible = true,
}) => {
  return (
    <>
      {drug.depletionsRaw && (
        <DrugNutrientDrugSpec
          title="Depletions"
          icon={<IconDepletions />}
          collapsible={collapsible}
        >
          <BlockContent blocks={drug.depletionsRaw} serializers={serializers} />
        </DrugNutrientDrugSpec>
      )}
      {drug.supportiveInteractionsRaw && (
        <DrugNutrientDrugSpec
          title="Supportive Interactions"
          icon={<IconSuppInteractions />}
          collapsible={collapsible}
        >
          <BlockContent blocks={drug.supportiveInteractionsRaw} serializers={serializers} />
        </DrugNutrientDrugSpec>
      )}
      {drug.negativeInteractionsRaw && (
        <DrugNutrientDrugSpec
          title="Negative Interactions"
          icon={<IconNegInteractions />}
          collapsible={collapsible}
        >
          <BlockContent blocks={drug.negativeInteractionsRaw} serializers={serializers} />
        </DrugNutrientDrugSpec>
      )}
      {drug.sideEffectsRaw && (
        <DrugNutrientDrugSpec
          title="Common Side Effects"
          icon={<IconSideEffects />}
          collapsible={collapsible}
        >
          <BlockContent blocks={drug.sideEffectsRaw} serializers={serializers} />
        </DrugNutrientDrugSpec>
      )}
      {drug.snps && <DrugNutrientDrugSpecGenes drug={drug} collapsible={collapsible} />}
      {drug.cautionRaw && (
        <DrugNutrientDrugSpec
          title="Caution / Other Notes"
          icon={<IconCaution />}
          collapsible={collapsible}
        >
          <BlockContent blocks={drug.cautionRaw} serializers={serializers} />
        </DrugNutrientDrugSpec>
      )}
    </>
  )
}
