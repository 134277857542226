import { createContext, MutableRefObject } from 'react'

export interface DrugNutrientPrintContextValue {
  isPrinting: boolean
  handlePrint: () => void
  contentElementRef: MutableRefObject<HTMLDivElement | null> | null
  setDocumentTitle: (title: string) => void
}

export const DrugNutrientPrintContext = createContext<DrugNutrientPrintContextValue>({
  isPrinting: false,
  contentElementRef: null,
  handlePrint: () => {
    // noop
  },
  setDocumentTitle: () => {
    // noop
  },
})
