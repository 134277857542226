import styled from 'styled-components'

import Link from '../../global/Link'

export const Breadcrumb = styled.div`
  font-family: ${({ theme }) => theme.fonts.component.button};
  color: ${({ theme }) => theme.colors.grayscale.gray60};
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 32px;

  @media print {
    display: none;
  }
`

export const Part = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.component.buttonLink};
  color: ${({ theme }) => theme.colors.grayscale.gray60};
`

export const Root = styled(Part)`
  text-decoration: underline;
`

export const Current = styled.span`
  color: ${({ theme }) => theme.colors.grayscale.black};
`

export const Chevron = styled.span`
  margin: 0 5px;
`
