import { Alert } from '@designsforhealth/dfh-react-components'
import { Subscribe } from '@react-rxjs/core'
import React, { FC, useContext, useEffect } from 'react'

import { useResultWithLeftEffect } from '../../../hooks/useResultWithLeftEffect'
import { drugNutrientBreadcrumb } from '../../../lib/drug-nutrient/config'
import { DrugNutrientPrintContext } from '../../../lib/drug-nutrient/context'
import {
  latestDrugDetails$,
  setDrugDetailsSlug,
  useDrugDetails,
} from '../../../lib/drug-nutrient/details'
import { constNull } from '../../../utils/constant'
import { pluckData } from '../../../utils/result'
import Spinner from '../../Spinner'
import { DrugNutrientBreadcrumb } from '../DrugNutrientBreadcrumb'
import { DrugNutrientDrugSpecsList } from '../DrugNutrientDrugSpecsList'
import { DrugNutrientDrugSpecsOverview } from '../DrugNutrientDrugSpecsOverview'
import { DrugNutrientLayout } from '../DrugNutrientLayout'
import { DrugNutrientNotFoundPage } from '../DrugNutrientNotFoundPage'
import { DrugNutrientSearch } from '../DrugNutrientSearch'
import * as Styled from './styled'

export interface DrugNutrientDrugSpecsPageProps {
  drugSlug: string
}

export const DrugNutrientDrugSpecsPageContent: FC<DrugNutrientDrugSpecsPageProps> = ({
  drugSlug,
}) => {
  const [error, drug] = useResultWithLeftEffect(useDrugDetails(drugSlug), pluckData, constNull)
  const { setDocumentTitle, handlePrint } = useContext(DrugNutrientPrintContext)

  useEffect(() => {
    setDocumentTitle(drug?.name ? `${drug.name} - Drug Details` : 'Drug Details')
  }, [drug?.name, setDocumentTitle])

  if (!drug) {
    return <DrugNutrientNotFoundPage />
  }
  return (
    <>
      {error && <Alert type="error">{error.error.message}</Alert>}
      <DrugNutrientBreadcrumb
        breadcrumb={drugNutrientBreadcrumb.drugSpecsPage({
          name: drug.name,
          slug: drug.slug?.current,
        })}
      />
      <Styled.Container>
        <DrugNutrientDrugSpecsOverview
          drug={drug}
          actions={
            <Styled.ExportButton onClick={handlePrint} variant="outlined" color="secondary">
              Export to PDF <Styled.ExportIcon />
            </Styled.ExportButton>
          }
        />
        <DrugNutrientDrugSpecsList drug={drug} collapsible={false} />
      </Styled.Container>
    </>
  )
}

export const DrugNutrientDrugSpecsPage: FC<DrugNutrientDrugSpecsPageProps> = ({ drugSlug }) => {
  useEffect(() => {
    setDrugDetailsSlug(drugSlug)
  }, [drugSlug])
  return (
    <DrugNutrientLayout>
      <DrugNutrientSearch currentDrugSlug={drugSlug} />
      <Subscribe source$={latestDrugDetails$(drugSlug)} fallback={<Spinner loading />}>
        <DrugNutrientDrugSpecsPageContent drugSlug={drugSlug} />
      </Subscribe>
    </DrugNutrientLayout>
  )
}
