import {
  CircularProgress,
  FormControl,
  IconButton,
  OutlinedInput,
  withStyles,
} from '@material-ui/core'
import { Clear as MuiClearIcon } from '@material-ui/icons'
import styled from 'styled-components'

import Link from '../../global/Link'

import SearchIconSvg from '../../../img/drug-nutrient/icon-search.inline.svg'

export const Container = styled.div`
  position: relative;
  z-index: 900;

  @media print {
    display: none;
  }
`

export const Search = styled.div`
  display: flex;
`

export const InputWrapper = styled(FormControl)`
  margin: 0;
  flex-grow: 1;
`

const inputHeight = '64px'

// customize internal components using MUI approach, but the input using styled-components
const MuiStyledInput = withStyles((theme) => ({
  notchedOutline: {
    borderColor: `${theme.nonMuiTheme.colors.warmGray.warm400} !important`,
  },
}))(OutlinedInput)
export const Input = styled(MuiStyledInput)`
  border-radius: 8px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  height: ${inputHeight};
  font-size: 16px;
  padding: 0;
`

export const InputElem = styled.input`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const SearchIconButton = styled(IconButton)`
  pointer-events: none;
  margin-left: 10px;
  padding: 12px 0 12px 12px;
`

export const SearchIcon = styled(SearchIconSvg)`
  width: 16px;
  height: 16px;
`

export const LoadingIcon = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-right: 22px;
`

export const ClearIconButton = styled(IconButton)`
  margin-right: 10px;
`

export const ClearIcon = styled(MuiClearIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const ResultList = styled.div`
  position: absolute;
  top: calc(${inputHeight} + 5px);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.brand.white};
  border: 1px solid ${({ theme }) => theme.colors.warmGray.warm400};
  border-radius: 8px;
  padding-bottom: 8px;
`

export const ResultListTitle = styled.div`
  color: ${({ theme }) => theme.colors.coolGray.cool600};
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray.cool150};
  font-size: 12px;
  padding: 16px;
  height: 46px;
`

export const ResultListContent = styled.div`
  max-height: 271px;
  overflow-y: auto;
`

export const ResultListEmpty = styled.div`
  color: ${({ theme }) => theme.colors.coolGray.cool600};
  font-size: 12px;
  padding: 16px 16px 8px;
`

export const ResultLine = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 54px;
  text-decoration: none;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.coolGray.cool600};
  padding: 8px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray.cool150};

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

export const ResultDrugName = styled.span`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;
`

export const ResultDrugBrandNames = styled.span`
  display: block;
  font-size: 12px;
`
