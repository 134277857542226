import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { drugNutrientPaths } from '../../lib/drug-nutrient/config'
import { usePageUrl } from '../../lib/pages'

const Container = styled.div`
  min-height: calc(60vh);
  padding: 80px 15px 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${PrimaryButton} {
    display: block;
    width: 100%;
    max-width: 344px;
  }
`

export const DrugNutrientNotFoundPage: FC = () => {
  const { navigate } = usePageUrl()
  return (
    <>
      <Helmet>
        <title>404: Not Found</title>
      </Helmet>
      <Container>
        <h1>Resource not found.</h1>
        <p>The resource you&#39;re looking for doesn&#39;t exist or couldn&#39;t be loaded.</p>
        <PrimaryButton
          onClick={() => navigate(drugNutrientPaths.homePage.absolute())}
          text="Return to Checker"
        />
      </Container>
    </>
  )
}
