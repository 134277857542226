import React, { FC, useEffect, useRef } from 'react'

import { ProductLabelStatus } from '../../../../graphql/search'
import useRetailPrices from '../../../../hooks/useRetailPrices'
import useSiteMetadata from '../../../../hooks/useSiteMetadata'
import { AnalyticsEventAddToCart } from '../../../../lib/analytics/state'
import {
  getVariantsKeyFromAttribute,
  Product,
  UseProducts,
  VariantAttributeKey,
} from '../../../../lib/products'
import { ProductDetails } from '../../../../lib/products/modal/types'
import { openQuickViewProductModal } from '../../../../lib/products/quick-view-modal'
import useSanityImage from '../../../../lib/sanity/hooks/useSanityImage'
import { isNotNull } from '../../../../utils/collectionTools'
import { formatAsCurrency } from '../../../../utils/priceTools'
import {
  AddToCartButton,
  ButtonWrapper,
  DosageTag,
  LabelStatusIcon,
  ProductAttribute,
  ProductAttributes,
  ProductDetailsContainer,
  ProductImage,
  ProductListCardContainer,
  ProductMinimumPriceAmount,
  ProductMinimumPriceContainer,
  ProductMinimumPriceText,
  ProductName,
  ProductPrice,
  ProductRetailPriceAmount,
  ProductRetailPriceContainer,
  ProductRetailPriceText,
  ScrollableContainer,
  SizeTag,
} from './styled'

import productImagePlaceholder from '../../../../img/product-image-placeholder.png'

export interface ProductListCardProps {
  className?: string
  hideFilters?: boolean
  product: Product
  sanityProductDetails?: ProductDetails
  page?: number
  effectCallback?: UseProducts['pagination']['actions']['lastOfPageEffectCallback'] | null
  analyticsEventAppContext?: AnalyticsEventAddToCart['app_context']
}

// TODO: Check if isAuthenticated is needed after M2 update to remove prices for guests
// TODO: Return prices when done correctly
const ProductListCard: FC<ProductListCardProps> = ({
  className = '',
  hideFilters = false,
  product,
  sanityProductDetails,
  page = 1,
  effectCallback = null,
  analyticsEventAppContext,
}) => {
  const { featureFlags } = useSiteMetadata() ?? {}
  const { shouldDisplayRetailPrice } = useRetailPrices()
  const productCardRef = useRef<HTMLDivElement>(null)

  const variantAttributes = (product.variants?.summary.attributes || []).map<VariantAttributeKey>(
    (attribute) => getVariantsKeyFromAttribute(attribute),
  )

  const sanityImageUrl = useSanityImage(
    { sourceJson: product.sanityImageJson || undefined },
    {
      dpr: 2,
      fit: 'max',
      // set width only to avoid crop bug: https://github.com/sanity-io/image-url/issues/32
      width: 225,
    },
  )
  const imageUrl = sanityImageUrl || productImagePlaceholder

  useEffect(() => {
    if (effectCallback && page) {
      return effectCallback(productCardRef.current, page)
    }
    // effectCallback missing is intentional to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCardRef.current, page])

  return (
    <ProductListCardContainer className={className} hideFilters={hideFilters} ref={productCardRef}>
      <ProductImage to={`/products/${product.urlKey}/`}>
        {featureFlags?.productLabelStatus && isNotNull(product.labelStatus) && (
          <LabelStatusIcon isLabelPresent={product.labelStatus === ProductLabelStatus.Present} />
        )}
        <img alt={product.name || undefined} src={imageUrl} />
      </ProductImage>
      <ProductDetailsContainer>
        <ProductName to={`/products/${product.urlKey}/`}>{product.name}</ProductName>
        <ProductAttributes>
          {variantAttributes.map((attributeKey, index) => {
            const values = product.variants?.summary.values[attributeKey] || []
            return (
              values.length > 0 &&
              attributeKey === 'dosage' && (
                <DosageTag key={`${product.urlKey}-${attributeKey}-${index + 1}`}>
                  {values.join(' / ')}
                </DosageTag>
              )
            )
          })}
          {variantAttributes.map((attributeKey, index) => {
            const values = product.variants?.summary.values[attributeKey] || []
            return (
              values.length > 0 &&
              (attributeKey === 'flavorSize' || attributeKey === 'flavor') && (
                <ProductAttribute key={`${product.urlKey}-${attributeKey}-${index + 1}`}>
                  {values.join(' / ')}
                </ProductAttribute>
              )
            )
          })}
          {variantAttributes.map((attributeKey) => {
            const values = product.variants?.summary.values[attributeKey] || []
            return values.length > 0 && attributeKey === 'size' ? (
              <ScrollableContainer key={`${attributeKey}-container`}>
                {values.map((value, index) => (
                  <SizeTag key={`${product.urlKey}-${attributeKey}-${index + 1}`}>{value}</SizeTag>
                ))}
              </ScrollableContainer>
            ) : null
          })}
        </ProductAttributes>
      </ProductDetailsContainer>
      <ButtonWrapper>
        {product.priceRange?.minimum && (
          <ScrollableContainer>
            <ProductPrice>
              <ProductMinimumPriceContainer>
                <ProductMinimumPriceText>
                  {product.priceRange?.minimum !== product.priceRange?.maximum ? `from ` : null}{' '}
                </ProductMinimumPriceText>
                <ProductMinimumPriceAmount>
                  {formatAsCurrency({ amount: parseFloat(product.priceRange?.minimum) })}
                </ProductMinimumPriceAmount>
              </ProductMinimumPriceContainer>
              {product.retailPriceRange?.minimum && shouldDisplayRetailPrice && (
                <ProductRetailPriceContainer>
                  <ProductRetailPriceText>retail</ProductRetailPriceText>
                  <ProductRetailPriceAmount>
                    {formatAsCurrency({
                      amount: parseFloat(product.retailPriceRange?.minimum),
                    })}
                  </ProductRetailPriceAmount>
                </ProductRetailPriceContainer>
              )}
            </ProductPrice>
          </ScrollableContainer>
        )}
        <AddToCartButton
          onClick={() =>
            openQuickViewProductModal({
              sku: product.sku,
              urlKey: product.urlKey,
              analyticsEventAppContext,
              productDetails: sanityProductDetails,
            })
          }
        >
          Add to Cart
        </AddToCartButton>
      </ButtonWrapper>
    </ProductListCardContainer>
  )
}

export default ProductListCard
