import { Button } from '@material-ui/core'
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 56px;
`

export const ExportButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.brand.blue};
  background-color: ${({ theme }) => theme.colors.brand.white};
  color: ${({ theme }) => theme.colors.brand.blue};
  width: 100%;
  height: 50px;
  text-transform: initial;
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 576px) {
    width: auto;
  }
  @media (min-width: 768px) {
    width: 286px;
  }
  @media print {
    display: none;
  }
`

export const ExportIcon = styled(DescriptionOutlinedIcon)`
  font-size: 16px;
  margin-left: 10px;
`
