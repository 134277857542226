import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 56px;
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 10px;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`

export const TitleWrapper = styled.div`
  flex-grow: 1;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.brand.blue};
`

export const Subtitle = styled.p`
  margin-bottom: 5pt;
`
